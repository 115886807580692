<template>
  <div id="app">
    <header>
      <div class="content">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="华云科技" class="logo" />
        </router-link>

        <nav>
          <router-link to="/" exact>网站首页</router-link>
          <router-link to="/company">公司介绍</router-link>
          <div class="dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
            <router-link to="/solutions" @click="setNewSections">解决方案</router-link>
            <div class="dropdown-menu" v-if="isDropdownVisible">
              <div class="dropdown-content">
                <div class="dropdown-section">
                  <div v-for="solution in solutions" :key="solution" class="text" @click="more(solution)">
                    {{ solution }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link to="/news">新闻资讯</router-link>
          <router-link to="/contact">联系我们</router-link>
        </nav>
      </div>
      <div class="search-login">
        <img src="@/assets/search.png" alt="搜索" class="search-icon" />
        <button class="login-button">登录</button>
      </div>
    </header>
    <router-view />
  </div>
</template>



<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const isDropdownVisible = ref(false);
const solutions = ref([
  'AI基础教育解决方案',
  'AI高职教解决方案',
  'AI医疗解决方案',
  // '华云天图AI教育管理解决方案',
  'AI政务服务解决方案',
  'AI企业应用解决方案'
]);

const store = useStore();
const router = useRouter();
const route = useRoute();

const updateSections = store.commit.bind(store, 'updateSections');

watch(route, () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

const showDropdown = () => {
  isDropdownVisible.value = true;
};

const hideDropdown = () => {
  isDropdownVisible.value = false;
};

const setNewSections = () => {
  let newSections = [
    {
      bg: {
        image: require('@/assets/solutions/bg.png'),
        title: 'AI基础教育解決方案',
        subtitle: '人工智能，让教育回归真正的教育'
      }
    },
    {
      breadcrumb: true
    },
    {
      title: "华云天图",
      introduce: {
        image: '',
        alt: '',
        texts: [
          '华云天图汇集华为盘古大模型、自研教育大模型、通义千问、智谱等多个大模型AI能力，集众家所长，构建模型开放、应用开放、全球领先的AI基座。我们专注于教育、政务和企业等领域，提供技术先进、数据安全的卓越AI服务，携手共进、迈入AI时代。华云天图AI教育包括数据空间、资源广场、零代码创作平台、AI项目式学习、AI集体备课、AI学生评价、AI家校共育、AI教学、AI测诊、AI教研、AI科研、AI继教、AI治理等应用。',
        ]
      }
    },
    {
      title: "人工智能，让教育回归真正的教育",
      description: {
        image: require('@/assets/solutions/education.png'),
        alt: '教育图片',
        texts: [
          '华云天图AI基础教育解決方案致力于推动区域教育的高质量发展。我们旨在使教育局成为AI时代的领航者，每所学校成为AI时代的先锋，每位教师成为AI时代的探索者，每个学生成为AI时代的受益者。我们专注于将最前沿的AI技术融入教育，为教育局和学校打造一个全方位的AI平台，用科技推动教育的变革。',
          '华云天图基于华为盘古大模型结合教育数据训练的教育专属行业模型。它提供了一系列全面的教育解决方案，包括资源积累、教学备课、教研科研、学习评估、行政管理和学校治理等方面。华云天图AI教育平台将AI技术应用于教学的全流程和全过程，从智能备课到个性化评估，从教研活动到教师专业成长，从课堂互动到家校交流，旨在使教学更智慧，学习更加高效。'
        ]
      }
    },
    {
      title: "需求与挑战",
      challenges: [
        {
          image: require('@/assets/solutions/ai_icon.png'),
          alt: 'ai',
          title: '顺应AI时代变革需求',
          text: '通过AI技术全面赋能学校的教育教学及业务流程，实现智能 化管理。助力教师因材施教，提供个性化教学方案，促进学 生个性化发展。全面提升学校的教学质量和治理水平。'
        },
        {
          image: require('@/assets/solutions/ai_icon.png'),
          alt: 'ai',
          title: 'AI技术和教育深度融合挑战大',
          text: 'AI技术的复杂性和专业性对教育工作者提出了新的要求，教 育工作者缺乏对AI技术的深入了解和应用能力，AI技术与教 育的结合需要持续的研究和创新，学校将面临巨大挑战。'
        },
        {
          image: require('@/assets/solutions/ai_icon.png'),
          alt: 'ai',
          title: '数据管理挑战大',
          text: '教育领域数据来源多样、格式不统一、质量不一，保密性与 安全性要求高，数据共享与协作需求增加，资源数据统计与 分析不足，影响资源配置和教学改进，制约教育质量提升。'
        }
      ]
    },
    {
      title: "基于华为盘古大模型的教育应用",
      model: {
        image: require('@/assets/solutions/education_model.jpg'),
        alt: '教育模型'
      }
    },
    {
      title: "部分案例",
      cases: [
        {
          image: require('@/assets/solutions/case1.png'),
          alt: '案例',
          title: '深圳市教育科学研究院',
          text: '基于教科院数据，训练专属教育大模型，为教研、科研、 培训、办公和管理全面赋能。通过人工智能技术，优化 教研设计、加速科研进程、提供个性化培训方案、提升 行政工作效率，全面提升教科研培的质量与效率。'
        },
        {
          image: require('@/assets/solutions/case2.png'),
          alt: '案例',
          title: '深圳市宝安中学（集团）',
          text: '基于宝安中学(集团)丰富数据和资源，结合先进AI技术，构建专属教育大模型及AI应用，全面赋能教育教学， 深度应用于师生教学与学习，助力学校高质量发展和拔 尖创新人才培养。'
        },
        {
          image: require('@/assets/solutions/case3.png'),
          alt: '案例',
          title: '深圳实验教育集团',
          text: 'AI助力深圳实验教育集团高质量发展项目，以人工智能算力、算法、教学资源为核心构建集团数字教育引擎AI基座，全面赋能学校教育教学等场景，将AI技术深度应用于师生教学与学习中，学习更高效、让治理更精准，引领深圳市基础教育高质量发展。'
        }
      ]
    }
  ];

  updateSections(newSections);
}

const more = (subtitle) => {
  let newSections = [
  ];
  switch (subtitle) {
    case 'AI基础教育解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/solutions/bg.png'),
            title: 'AI基础教育解決方案',
            subtitle: '人工智能，让教育回归真正的教育'
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "华云天图",
          introduce: {
            image: '',
            alt: '',
            texts: [
              '华云天图汇集华为盘古大模型、自研教育大模型、通义千问、智谱等多个大模型AI能力，集众家所长，构建模型开放、应用开放、全球领先的AI基座。我们专注于教育、政务和企业等领域，提供技术先进、数据安全的卓越AI服务，携手共进、迈入AI时代。华云天图AI教育包括数据空间、资源广场、零代码创作平台、AI项目式学习、AI集体备课、AI学生评价、AI家校共育、AI教学、AI测诊、AI教研、AI科研、AI继教、AI治理等应用。',
            ]
          }
        },
        {
          title: "人工智能，让教育回归真正的教育",
          description: {
            image: require('@/assets/solutions/education.png'),
            alt: '教育图片',
            texts: [
              '华云天图AI基础教育解決方案致力于推动区域教育的高质量发展。我们旨在使教育局成为AI时代的领航者，每所学校成为AI时代的先锋，每位教师成为AI时代的探索者，每个学生成为AI时代的受益者。我们专注于将最前沿的AI技术融入教育，为教育局和学校打造一个全方位的AI平台，用科技推动教育的变革。',
              '华云天图基于华为盘古大模型结合教育数据训练的教育专属行业模型。它提供了一系列全面的教育解决方案，包括资源积累、教学备课、教研科研、学习评估、行政管理和学校治理等方面。华云天图AI教育平台将AI技术应用于教学的全流程和全过程，从智能备课到个性化评估，从教研活动到教师专业成长，从课堂互动到家校交流，旨在使教学更智慧，学习更加高效。'
            ]
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/solutions/ai_icon.png'),
              alt: 'ai',
              title: '顺应AI时代变革需求',
              text: '通过AI技术全面赋能学校的教育教学及业务流程，实现智能 化管理。助力教师因材施教，提供个性化教学方案，促进学 生个性化发展。全面提升学校的教学质量和治理水平。'
            },
            {
              image: require('@/assets/solutions/ai_icon.png'),
              alt: 'ai',
              title: 'AI技术和教育深度融合挑战大',
              text: 'AI技术的复杂性和专业性对教育工作者提出了新的要求，教 育工作者缺乏对AI技术的深入了解和应用能力，AI技术与教 育的结合需要持续的研究和创新，学校将面临巨大挑战。'
            },
            {
              image: require('@/assets/solutions/ai_icon.png'),
              alt: 'ai',
              title: '数据管理挑战大',
              text: '教育领域数据来源多样、格式不统一、质量不一，保密性与 安全性要求高，数据共享与协作需求增加，资源数据统计与 分析不足，影响资源配置和教学改进，制约教育质量提升。'
            }
          ]
        },
        {
          title: "基于华为盘古大模型的教育应用",
          model: {
            image: require('@/assets/solutions/education_model.jpg'),
            alt: '教育模型'
          }
        },
        {
          title: "部分案例",
          cases: [
            {
              image: require('@/assets/solutions/case1.png'),
              alt: '案例',
              title: '深圳市教育科学研究院',
              text: '基于教科院数据，训练专属教育大模型，为教研、科研、 培训、办公和管理全面赋能。通过人工智能技术，优化 教研设计、加速科研进程、提供个性化培训方案、提升 行政工作效率，全面提升教科研培的质量与效率。'
            },
            {
              image: require('@/assets/solutions/case2.png'),
              alt: '案例',
              title: '深圳市宝安中学（集团）',
              text: '基于宝安中学(集团)丰富数据和资源，结合先进AI技 术，构建专属教育大模型及AI应用，全面赋能教育教学， 深度应用于师生教学与学习，助力学校高质量发展和拔 尖创新人才培养。'
            },
            {
              image: require('@/assets/solutions/case3.png'),
              alt: '案例',
              title: '深圳实验教育集团',
              text: 'AI助力深圳实验教育集团高质量发展项目，以人工智能算力、算法、教学资源为核心构建集团数字教育引擎AI基座，全面赋能学校教育教学等场景，将AI技术深度应用于师生教学与学习中，学习更高效、让治理更精准，引领深圳市基础教育高质量发展。'
            }
          ]
        }
      ];
      break;
    case 'AI企业应用解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/smartCorp/bg.png'),
            title: 'AI企业应用解决方案',
            subtitle: '为企业提供从数据预处理到应用部署的全方位AI应用支持'
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "概述",
          description: {
            image: require('@/assets/smartCorp/education.png'),
            alt: '教育图片',
            texts: [
              '我们致力于为企业提供从数据预处理到应用部署的全方位支持，旨在为不同行业、不同规模的企业提供共性AI应用及定制 化的AI落地方案，带来高效、专业的解决方案。我们的服务涵盖业务流程体系改造咨询、方案设计、大模型选型、实施及 全员培训等多个维度，确保用户能够高效、无缝地将AI技术融入日常运营中。通过优化业务流程、定制AI方案、选择合适 模型和提供全面培训，我们帮助企业在数字化转型过程中实现高效运营和持续创新，推动整个数字经济的繁荣发展。我们 的目标是成为企业在AI应用领域的可靠伙伴，助力其在激烈的市场竞争中脱颖而出。',
              '']
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/smartCorp/ai_icon.png'),
              alt: 'ai',
              title: '降低企业运营成本，提升企业竞争力',
              text: ' AI可以增强产品和服务的创新能力，提供个性化解决方案， 满足客户多样化需求。总之，AI技术为企业提供了强大的工 具，助力其在激烈的市场竞争中保持领先地位。'
            },
            {
              image: require('@/assets/smartCorp/ai_icon.png'),
              alt: 'ai',
              title: '技术与人才短缺',
              text: 'AI涉及复杂的算法和模型，企业在过程中面临技术难题，如 模型训练、算法优化和系统集成等。企业需要具备专业团队 来开发和维护。'
            },
            {
              image: require('@/assets/smartCorp/ai_icon.png'),
              alt: 'ai',
              title: '数据质量',
              text: 'AI系统的性能高度依赖于数据的质量和数量。企业需要确保 数据的准确性、完整性和及时性。然而，收集和处理高质量 数据往往面临技术和成本上的挑战。'
            }
          ]
        },
        {
          title: "AI伙伴，驱动企业创新发展  ",
          model: {
            image: require('@/assets/smartCorp/education_model.jpg'),
            alt: '教育模型'
          }
        },
        {
          title: "部分应用场景",
          cases: [
            {
              image: require('@/assets/smartCorp/case1.png'),
              alt: '场景',
              title: 'AI个性化营销',
              text: '利用AI技术进行客户细分、推荐系统和营销自动化，提升 客户转化率和满意度。'
            },
            {
              image: require('@/assets/smartCorp/case2.png'),
              alt: '场景',
              title: 'AI人力资源管理',
              text: '通过AI技术优化招聘筛选、员工绩效评估和个性化培训， 提升人力资源管理效率和员工满意度。'
            },
            {
              image: require('@/assets/smartCorp/case3.png'),
              alt: '场景',
              title: 'AI财务风险管理',
              text: '利用AI进行信用评估、欺诈检测和财务预测，降低财务 风险并支持科学决策。'
            }
          ]
        }
      ];
      break;
    case 'AI高职教解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/smartVocEd/bg.png'),
            title: 'AI高职教解决方案',
            subtitle: '通过人工智能技术，赋能职业院校教育教学、科研、学生培养和学校治理等'
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "概述",
          description: {
            image: require('@/assets/smartVocEd/education.png'),
            alt: '教育图片',
            texts: [
              '致力于通过人工智能技术，全面赋能学校的教育教学、科研创新、学生培养和学校治理等各个方面。我们希望学校能够成为AI时代的探索者，积极引入和应用先进的人工智能技术，推动教育模式的变革与创新。教师则成为AI时代的先行者，利用智能化教学工具和数据分析手段，提高教学质量和效率，个性化地指导学生成长。学生则成为AI时代的受益者，通过AI技术获得更丰富的学习资源和更精准的学习支持，全面提升专业技能和综合素质，适应快速变化的时代需求。',
              '我们致力于通过这些努力，全面助力学生的专业发展，使他们在未来的职业生涯中具备竞争力和适应力。']
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/smartVocEd/ai_icon.png'),
              alt: 'ai',
              title: '培养适应时代需要的人才',
              text: '当前教育正向个性化、智能化转型，利用最新的AIGC技术，促进教育模式的创新和教学方法的改革，结合人才培养方案和教学大纲，提供与未来教育需求相匹配的实践教学环境。'
            },
            {
              image: require('@/assets/smartVocEd/ai_icon.png'),
              alt: 'ai',
              title: '师资培训与能力提升挑战大',
              text: '面对AIGC的快速发展，职业院校教师需要不断更新知识结构与教学方法，旨在提升教师在AI领域的专业知识、教学技巧及实际操作能力，学校及教育工作者将面临巨大挑战。'
            },
            {
              image: require('@/assets/smartVocEd/ai_icon.png'),
              alt: 'ai',
              title: '培养满足社会需求人才挑战大',
              text: '培养满足需求的人才面临多重挑战。社会需求多样，教育需灵活调整；学生个性化需求增加，需因材施教；综合素质和创新能力要求高，传统教育难以满足。'
            }
          ]
        },
        {
          title: "AI赋能高职教，培育创新英才",
          model: {
            image: require('@/assets/smartVocEd/education_model.jpg'),
            alt: '教育模型'
          }
        },
        {
          title: "部分应用场景",
          cases: [
            {
              image: require('@/assets/smartVocEd/case1.png'),
              alt: '场景',
              title: 'AI教学',
              text: '通过AI技术提供个性化学习路径、智能答疑和自动评测，提升学习效率和效果。'
            },
            {
              image: require('@/assets/smartVocEd/case2.png'),
              alt: '场景',
              title: 'AIGC实训平台',
              text: '培养学生们掌握并灵活应用先进科技技能的能力，以便在日新月异的数字时代中创新和解决问题。'
            },
            {
              image: require('@/assets/smartVocEd/case3.png'),
              alt: '场景',
              title: 'AI职业发展指导',
              text: '通过AI分析提供个性化职业规划、就业推荐和技能提升建议，助力学生职业发展。'
            }
          ]
        }
      ];
      break;
    case 'AI教育管理解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/smartEduManage/bg.png'),
            title: 'AI教育管理解决方案',
            subtitle: '致力于用AI技术，构建教育新场景、新连接、新生态，赋能教育局在教研、科研、培训和管理全过程'
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "概述",
          description: {
            image: require('@/assets/smartEduManage/education.png'),
            alt: '教育图片',
            texts: [
              '我们致力于利用人工智能新技术，构建全新的教育场景、建立新的连接和打造新的生态系统，全面赋能教育局在教研、科 研、培训和管理全部程中的各个环节。通过引入先进的AI技术，我们提升了教学质量和教研创新能力，推动了学术研究的 发展，助力拔尖创新人才的培养。我们的目标是成为教育领域最强有力的数字教育新引擎，迎接教育变革与发展的挑战， 实现人才培养对中国式现代化的有力支撑，为国家的未来发展提供坚实的人才基础。通过数字技术的赋能，推动教育事业 的全面进步，迎接新时代的教育变革与发展。',
              ''
            ]
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/smartEduManage/ai_icon.png'),
              alt: 'ai',
              title: '提升区域教育高质量发展',
              text: '强化教育治理能力，优化治理结构，提升服务水平，构建完善的区域教育制度体系，实现价值引领。推进区域教育智能化，抓住数字化转型机遇，赋能基础教育高质量发展。'
            },
            {
              image: require('@/assets/smartEduManage/ai_icon.png'),
              alt: 'ai',
              title: '教育发展模式实现创新变革',
              text: '人工智能、大数据等技术迅猛发展，改变了人才需求和教育形态。教育管理者需融入技术变革，推动数字化教育治理，建设现代化监测与决策系统，完善数据标准，培养拔尖人才，加快基础教育创新，适应未来趋势。'
            },
            {
              image: require('@/assets/smartEduManage/ai_icon.png'),
              alt: 'ai',
              title: '教育更加公平更高质量',
              text: '人民对高质量教育的期待迫切，追求个性化、人文化和灵活学习方式。必须加快建设高质量教育体系，提高各级教育质量，增强优质教育供给，提升群众教育获得感和满意度。'
            }
          ]
        },
        {
          title: "智能教育管理新引擎",
          model: {
            image: require('@/assets/smartEduManage/education_model.jpg'),
            alt: '教育模型'
          }
        },
        {
          title: "部分应用场景",
          cases: [
            {
              image: require('@/assets/smartEduManage/case1.png'),
              alt: '场景',
              title: 'AI教育数据分析',
              text: '利用大数据和机器学习技术，分析学生成绩、教师绩效和学校运营数据，提供决策支持，提升教育质量和管理效率。'
            },
            {
              image: require('@/assets/smartEduManage/case2.png'),
              alt: '场景',
              title: 'AI教育政策影响模拟预测',
              text: '在制定或调整教育政策前，利用AI模型模拟新政策对教育 系统可能产生的影响。'
            },
            {
              image: require('@/assets/smartEduManage/case3.png'),
              alt: '场景',
              title: 'AI教育资源优化配置',
              text: '利用AI技术分析教育资源需求和分布情况，优化教师、教 材和设施的配置，提升教育资源利用率和公平性。'
            }
          ]
        }
      ];
      break;
    case 'AI医疗解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/treatment/bg.png'),
            title: 'AI医疗解决方案',
            subtitle: '通过智能化技术提升诊断精度与治疗效率，优化医疗资源分配，实现高质量医疗服务。 '
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "概述",
          description: {
            image: require('@/assets/treatment/education.png'),
            alt: '医疗图片',
            texts: [
              '华云科技AI医疗解决方案，依托于前沿的人工智能技术，结合对医学领域深刻的理解与洞察，来解决当前医疗体系面临的挑战。',
              '基于华为盘古大模型，结合医疗行业研究数据、诊疗数据、个人健康数据、药品数据等训练的医疗健康领域专属大模型。它为个人提供了专属健康数字分身、为医生提供诊疗辅助智能体、为诊疗机构提供从信息流转到业务流程推动各环节的解决方案。华云天图AI医疗平台将AI技术应用到医疗健康全领域，覆盖个人日常健康管理、就诊前中后专业咨询与管理，打造长效的健康管理机制。为医生提供全面的患者日常健康数据，诊疗建议，帮助医生提升更精准的医疗服务。'
            ]
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/treatment/ai_icon.png'),
              alt: 'ai',
              title: '居民无权威健康咨询渠道',
              text: '除就诊问医外，居民缺乏日常健康咨询渠道，无法获取专业的健康指导。这导致患者无法准确把握就诊时机，轻诊就医导致医疗资源占用、浪费个人时间；部分病症未即使就医以至小病拖大病甚至失去治疗时机'
            },
            {
              image: require('@/assets/treatment/ai_icon.png'),
              alt: 'ai',
              title: '医生事务繁杂，无法实现高效、精准诊疗',
              text: '患者往往无法准确全面的表达自己的不适，需要不断引导以获取信息，问诊对话占据大量的诊疗时间且效率不高；诊疗过程靠经验支撑，无法即使获取权威信息支持。导致无法向患者提供个性化精准诊疗服务。'
            },
            {
              image: require('@/assets/treatment/ai_icon.png'),
              alt: 'ai',
              title: '缺乏长效医患沟通渠道',
              text: '诊后沟通渠道缺失。目前医院诊后随访覆盖范围有限，且 人工沟通为主，院方发起；患者除再次就医外无法主动与诊疗机构沟通。导致诊后的健康状况跟踪无法闭环'
            }
          ]
        },
        {
          title: "AI赋能医疗，为医疗提质增效",
          text:'依托人工智能技术让每位患者都能享受到个性化和精准化的治疗；医生能够获得全面准确的诊疗支持；诊疗组织管理也将更加智能高效。',
          model: {
            image: require('@/assets/treatment/education_model.png'),
            alt: '医疗模型'
          }
        },
        {
          title: "部分应用场景",
          cases: [
            {
              image: require('@/assets/treatment/case1.png'),
              alt: '场景',
              title: '个人健康数字分身',
              text: '华云医疗健康大模型整合权威数据，提供个性化健康咨询与管理，构建智能个人健康顾问。'
            },
            {
              image: require('@/assets/treatment/case2.png'),
              alt: '场景',
              title: '医生诊疗助手',
              text: '华云医疗系统呈现患者健康信息，提供诊疗辅助，提高医生效率，支持个性化医疗。'
            },
            {
              image: require('@/assets/treatment/case3.png'),
              alt: '场景',
              title: '长效的医患互动渠道',
              text: '华云医疗大模型通过健康数字分身为患者提供日常健康管理与答疑，必要时引导及时就医。'
            }
          ]
        }
      ];
      break;
    case 'AI政务服务解决方案':
      newSections = [
        {
          bg: {
            image: require('@/assets/smartGov/bg.png'),
            title: 'AI政务服务解决方案',
            subtitle: '为政务领域提供从数据处理、应用部署、模型训练的全方位支持'
          }
        },
        {
          breadcrumb: true
        },
        {
          title: "概述",
          description: {
            image: require('@/assets/smartGov/education.png'),
            alt: '教育图片',
            texts: [
              '我们致力于为政务领域提供全方位的支持，从数据处理、应用部署到模型训练，覆盖各个环节。我们的目标是为政务领域 提供共性“AI+智能办公场景”和“AI+政务服务场景”，以及定制化的AI落地方案。通过方案，我们能够在事项办理、政 务信息咨询和政务服务监管等方面实现智能化，全面助力政府的数智化转型。我们利用先进的数字技术，推动政府从数据 化向智能化阶段加速迈进，为国家治理体系和治理能力的现代化提供强有力的支撑。我们的解决方案不仅提高了政务工作 的效率和准确性，还提升了公众对政府服务的满意度和信任度。通过不断创新和优化，我们致力于为政府部门提供最优质 的智能化服务，助力国家治理体系的现代化进程。',
              ''
            ]
          }
        },
        {
          title: "需求与挑战",
          challenges: [
            {
              image: require('@/assets/smartGov/ai_icon.png'),
              alt: 'ai',
              title: '政务治理现代化',
              text: '数字技术拓展政府服务能力，细化管理颗粒度，解决人物力不足。加快数字政府建设是推动治理现代化、适应数字化转型和建设服务型政府的关键。'
            },
            {
              image: require('@/assets/smartGov/ai_icon.png'),
              alt: 'ai',
              title: '数据资源',
              text: '政务数据涉及跨部门、跨层级，面临数据共享难、数据质量低、数据回流慢等问题，导致信息孤岛现象严重，影响了政务服务的效率和准确性，亟需解决。'
            },
            {
              image: require('@/assets/smartGov/ai_icon.png'),
              alt: 'ai',
              title: '技术可信度和透明度',
              text: '由于大模型在专业知识方面的占比较低，应用于政府治理活动时，可能会产生不准确、不连贯和不完整的内容，从而影响决策的科学性和政策的有效性，需谨慎使用。'
            }
          ]
        },
        {
          title: "AI赋能政务，构建智慧型政府",
          model: {
            image: require('@/assets/smartGov/education_model.jpg'),
            alt: '教育模型'
          }
        },
        {
          title: "部分应用场景",
          cases: [
            {
              image: require('@/assets/smartGov/case1.png'),
              alt: '场景',
              title: 'AI政务咨询',
              text: '利用自然语言处理技术，提供24/7的自动化政务咨询服务， 快速解答市民的常见问题。'
            },
            {
              image: require('@/assets/smartGov/case2.png'),
              alt: '场景',
              title: 'AI审批系统',
              text: '通过机器学习和大数据分析，优化审批流程，提高审批效率和准确性，减少市民等待时间。'
            },
            {
              image: require('@/assets/smartGov/case3.png'),
              alt: '场景',
              title: 'AI民生服务',
              text: '通过AI技术整合各类民生服务资源，提供个性化的服务 推荐和便捷的在线办理，提升市民的生活质量和满意度。'
            }
          ]
        },
        {
          title: "",
          model: {
            image: require('@/assets/smartGov/quote.png'),
            alt: '报价'
          }
        },
      ];
      break;
    default:
      break;
  }

  updateSections(newSections);
  router.push('/solutions')
}
</script>


<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 56px;
  background-color: #fff;
}

.logo {
  height: 40px;
}

.content {
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  align-items: center;
  gap: 72px;
  margin-left: 60px;
}

nav a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  height: 80px;
  position: relative;
  font-weight: normal;
}

nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s;
}

nav a.router-link-exact-active {
  color: #c71b24;
  font-weight: bold;
}

nav a.router-link-exact-active::after {
  background-color: #c71b24;
}

.search-login {
  display: flex;
  align-items: center;
}

.search-icon {
  height: 24px;
  cursor: pointer;
  margin-right: 40px;
}

.login-button {
  background-color: transparent;
  font-size: 14px;
  border: none;
  color: #000;
  cursor: pointer;
  line-height: 40px;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  gap: 40px;
}

.dropdown-content {
  width: 800px;
  display: flex;
  flex-direction: column;
}

.dropdown-section {
  display: flex;
  flex-direction: column;
}

.dropdown-section .text {
  margin: 20px;
  cursor: pointer;
}

.dropdown-section .text:hover {
  color: #cc2225;
}


.dropdown-section ul {
  list-style: none;
}

.dropdown-section ul li {
  margin-bottom: 5px;
}

@media (max-width: 1440px) {
  header {
    padding: 0 40px;
  }

  nav {
    gap: 50px;
    margin-left: 40px;
  }

  .search-icon {
    margin-right: 30px;
  }
}

@media (max-width: 1280px) {
  header {
    padding: 0 30px;
  }

  nav {
    gap: 30px;
    margin-left: 30px;
  }

  .search-icon {
    margin-right: 20px;
  }
}

@media (max-width: 1080px) {
  header {
    padding: 0 20px;
  }

  nav {
    gap: 20px;
    margin-left: 20px;
  }

  .search-icon {
    margin-right: 10px;
  }

  .login-button {
    font-size: 12px;
  }
}
</style>
